import React from "react";
import { Link } from "react-router-dom";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { entries } from "../components/Blog/entries";
import { NewsEntry } from "../components/Blog/entry";
import { newsletterEntries } from "../components/Blog/newsletterEntries";
import {
  NewsletterHeading,
  NewsPageWrap,
  NewsStyle,
  NewsTickerStyle,
  TwitterFeedDesktopWrap,
  TwitterFeedMobileWrap,
  TwitterFeedWrap,
} from "../components/Blog/styles";
import { AExternal } from "../components/Links";
import { TWITTER_ID } from "../config";
import { ConstrainedPageStyle, P, PageTitle, Section, SectionHeading, SectionBackground } from "../styles/page";

const NewsPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <SectionBackground>
        <PageTitle>SAMHE News</PageTitle>
      </SectionBackground>
      <NewsPageWrap>
        <NewsStyle>
          <NewsletterHeading>News</NewsletterHeading>
          <NewsTickerStyle>
            {entries.map((entry) => (
              <NewsEntry key={entry.title} {...entry} />
            ))}
          </NewsTickerStyle>
        </NewsStyle>
        {false && (
          <TwitterFeedWrap>
            <NewsletterHeading>
              <AExternal href="https://twitter.com/samheproject">X (Twitter) Feed</AExternal>
            </NewsletterHeading>
            <TwitterFeedDesktopWrap>
              <TwitterTimelineEmbed
                sourceType="profile"
                screenName={TWITTER_ID}
                autoHeight
                noHeader
                noBorders
                transparent
              />
            </TwitterFeedDesktopWrap>
            <TwitterFeedMobileWrap>
              <AExternal href="https://twitter.com/samheproject" />
            </TwitterFeedMobileWrap>
          </TwitterFeedWrap>
        )}
        <NewsStyle>
          <NewsletterHeading>Newsletters</NewsletterHeading>
          <NewsTickerStyle>
            {newsletterEntries.map((entry) => (
              <NewsEntry key={entry.title} {...entry} />
            ))}
          </NewsTickerStyle>
        </NewsStyle>
      </NewsPageWrap>
      <br></br>
      <Section>
        <SectionHeading>Sign up for the SAMHE newsletter</SectionHeading>
        <P>
          Use the <Link to="/faq#contact">Contact Form</Link> to join our mailing list and get the SAMHE newsletter direct
          to your inbox.
        </P>
      </Section>
      <Section>
        <SectionHeading>Contribute to the SAMHE newsletter!</SectionHeading>

        <P>
          If you are a staff member or pupil at a SAMHE school and would like to share a story about how your school is
          using SAMHE, we would love to hear from you!
        </P>
        <P>
          We're keen to use the newsletter to develop a SAMHE community, facilitate knowledge sharing and celebrate your
          successes. Perhaps you have developed some interesting experiments which other schools might be interested in?
          Or started a new air quality initiative? Or produced some exciting pieces of writing or artwork about air
          quality?
        </P>
        <P>
          Visit our{" "}
          <AExternal href="https://docs.google.com/forms/d/e/1FAIpQLSdLn1k7Tw-Z1FXqyPCWfLLf64d7fx8mYZcWBXk6WsV9JDSPyg/viewform?usp=sf_link">
            Newsletter Contributions Form
          </AExternal>{" "}
          to find out more and submit your contribution. If you are under the age of 18 you will need to ask a teacher,
          parent or guardian to complete this form on your behalf.
        </P>
      </Section>
    </ConstrainedPageStyle>
  );
};

export default NewsPage;
