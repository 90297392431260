import React from "react";
import { Link } from "react-router-dom";
import { AExternal } from "../components/Links";
import { HeroBackground, BackgroundHeroStyle, BoxoutHero, BoxoutContactStyle } from "../styles/hero";
import {
  ConstrainedPageStyle,
  PageTitle,
  Section,
  SectionHeading,
  P,
  Ul,
  FlexFlipWrap,
  CenteredTextContainer,
  SnapRightImg,
  SubSectionHeading,
  FloatImageMax,
  TableOfContentsWrapper,
  TableOfContents,
  SectionBackground,
  PLong,
} from "../styles/page";
import ScrollTopButton from "../GenericComponents/Buttons/ScrollTopButton";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { FlexBox } from "../styles/singlePageStyles";

const PlayerWrapper = styled.div`
  float: right;
  width: 50%;
  margin: 0;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    float: none;
    width: 100%;
    margin-bottom: 3rem;
    margin-top: 1rem;
  }
`;

const HowSAMHEWorkedPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <SectionBackground>
        <PageTitle>How SAMHE works</PageTitle>
        <BackgroundHeroStyle>
          <HeroBackground src="/images/get-involved/KS3_4k.jpg" />
        </BackgroundHeroStyle>
        <P style={{ fontSize: "small", paddingLeft: "0.5rem", paddingTop: "0.5rem" }}>
          Credit{" "}
          <AExternal href="https://www.istockphoto.com/portfolio/monkeybusinessimages?mediatype=photography">
            monkeybusinessimages
          </AExternal>
        </P>
      </SectionBackground>
      <TableOfContentsWrapper>
        <div>
          <TableOfContents>
            <h4>Contents:</h4>
            <Ul>
              <li>
                <Link to="#what-samhe-schools-get">What schools get</Link>
              </li>
              <li>
                <Link to="#how-it-works">How it works</Link>
              </li>
            </Ul>
          </TableOfContents>
        </div>
        <div>
          <BoxoutContactStyle style={{ marginBottom: "2rem" }}>
            <Section>
              <PLong>
                For any schools that registered before May 31 2024, this page explains what being involved could look
                like for your school.
              </PLong>
              <PLong>
                Don't worry if you missed the deadline - Our <Link to="/get-involved">Teacher Resource Pack</Link> is
                now live! It enables schools without a SAMHE monitor to access many of the educational activities in the
                Web App, including quizzes, data analysis, videos, and creative activities. Teacher guidance is embedded
                throughout and we have included example data and graphs for pupils to work with.
              </PLong>
              <PLong>
                Sign up to the SAMHE newsletter via our <Link to="/faq#contact">contact form</Link> to keep up with
                project updates, scientific findings on air quality in schools and inspiring stories of how schools are
                using their monitor and the Web App, or the Teacher Resource Pack, to learn about their classroom air.
                You may also like to follow the{" "}
                <AExternal href="https://twitter.com/SAMHEProject">@SAMHEproject on X</AExternal> or{" "}
                <AExternal href="https://www.linkedin.com/feed/hashtag/?keywords=samhe">
                  hashtag #SAMHE on Linkedin
                </AExternal>
                , to keep up to date with the latest SAMHE developments.
              </PLong>
            </Section>
          </BoxoutContactStyle>
          <Section>
            <SectionHeading id="what-samhe-schools-get">What SAMHE schools get</SectionHeading>
            <FlexFlipWrap>
              <CenteredTextContainer>
                <div>
                  <SubSectionHeading>High spec air quality monitor</SubSectionHeading>
                  <P>
                    If you registered before 31 May 2024, your school will have received a free high spec air quality
                    monitor that measures <b>carbon dioxide</b> (CO<sub>2</sub>
                    ), <b>volatile organic compounds</b> (TVOCs), <b>particulate matter</b> (PM), <b>temperature</b> and{" "}
                    <b>relative humidity</b>.
                  </P>
                </div>
              </CenteredTextContainer>
              <CenteredTextContainer>
                <SnapRightImg src="/images/get-involved/Monitor-panorama.jpg" alt="Samhe monitor" />
              </CenteredTextContainer>
            </FlexFlipWrap>
          </Section>
          <Section>
            <SubSectionHeading>SAMHE interactive Web App</SubSectionHeading>
            <P>
              Teachers and pupils can access their monitor data through a specially designed interactive Web App, seeing
              how air quality changes over the course of hours, days or weeks and months.
            </P>
            <FloatImageMax
              style={{ borderRadius: "0.3rem", boxShadow: "0 4px 4px rgba(0, 0, 0, 0.5)" }}
              src="/images/webapp_landing_page.jpg"
              alt="Samhe Web App"
            />
            <P>
              The Web App also offers a range of related <b>activities and experiments</b>, creating opportunities for
              pupils to be scientists and do hands-on experiments with their monitor. Pupils can have individual
              accounts, enabling them to explore their monitor data for themselves, do activities individually and track
              their own achievements. For more information see the FAQ '
              <Link to="/support#how_can_i_set_up_pupil_accounts">How can I set up pupil accounts?</Link>' or watch the
              video '<AExternal href="https://youtu.be/iu1jYjgkjSA">How to set up pupil accounts</AExternal>'.
            </P>
          </Section>
          <Section>
            <BoxoutHero>
              <FlexFlipWrap>
                <CenteredTextContainer>
                  <P>
                    <SubSectionHeading>The Web App includes activities:</SubSectionHeading>
                    <Ul>
                      <li>
                        <b>For a range of learning aims, group types and session durations</b> (subject lessons,
                        extension activities, extracurricular clubs, careers activities, extended projects, 5 minute
                        tasks…)
                      </li>
                      <li>
                        <b>For different age groups</b> (key stages 1-4 plus sixth form)
                      </li>
                      <li>
                        <b>Developing a range of skills</b>, including scientific enquiry, data logging and data
                        handling, understanding graphs and charts, reasoning with evidence and persuasive writing
                      </li>
                      <li>
                        <b>With clear curriculum links</b> for a range of subjects including, but not limited to,
                        Science, Maths and PSHE. Lesson plans, videos and other resources will also be provided
                      </li>
                      <li>
                        <b>With career links</b>, including information about the SAMHE project team members and their
                        career pathways
                      </li>
                      <li>
                        Using <b>art and design</b> skills
                      </li>
                    </Ul>
                  </P>
                </CenteredTextContainer>
                <CenteredTextContainer>
                  <SnapRightImg
                    src="/images/get-involved/activity-card.jpg"
                    alt="Samhe web app activity card"
                    style={{ borderRadius: "0.3rem" }}
                  />
                </CenteredTextContainer>
              </FlexFlipWrap>
              <FloatImageMax
                style={{ borderRadius: "0.3rem", boxShadow: "0 4px 4px rgba(0, 0, 0, 0.5)" }}
                src="/images/get-involved/web_app_datavis.jpg"
                alt="Samhe web app data visualisation"
              />
            </BoxoutHero>
          </Section>
          <Section>
            <P>
              More activities will be added to the Web App during the course of the SAMHE project. For an introduction
              to the SAMHE Web App, watch the short Web App Walkthrough and Web App Activities videos below.
            </P>
            <FlexBox horiz style={{ gap: "2rem" }}>
              <PlayerWrapper>
                <ReactPlayer url="https://youtu.be/Z2WA4hx8mRQ" controls={true} width="100%" />
              </PlayerWrapper>
              <PlayerWrapper>
                <ReactPlayer url="https://youtu.be/6VLvedLvxMM" controls={true} width="100%" />
              </PlayerWrapper>
            </FlexBox>
          </Section>
          <Section>
            <SectionHeading id="how-it-works">How it works</SectionHeading>
            <FloatImageMax
              // style={{ borderRadius: "0.3rem", boxShadow: "0 4px 4px rgba(0, 0, 0, 0.5)", border:"1px solid black"}}
              src="/images/get-involved/SAMHE_process_infographic.jpg"
              alt="Infographic showing what being a Sammy school involves, with the following four steps. You receive and set up your Sammy monitor. Sammy scientists set up activities on the Sammy Web App. Pupils complete fun activities, learning about air quality and inputting important contextual data. The data goes to Sammy scientists so they can analyse and report on air quality in UK classrooms."
            />
          </Section>

          <Section>
            <FlexFlipWrap>
              <CenteredTextContainer>
                <div>
                  <SubSectionHeading>The chance to be part of something bigger!</SubSectionHeading>
                  <P>
                    Taking part in SAMHE gives pupils the opportunity to contribute to genuine scientific research,
                    working together with scientists and thousands of other school children towards a cleaner, healthier
                    school environment. It also{" "}
                    <Link to="/faq/#Infoforpupils">looks great on a personal statement!</Link>
                  </P>
                </div>
              </CenteredTextContainer>
              <CenteredTextContainer>
                <SnapRightImg src="/images/get-involved/sixthformers-computer.jpg" alt="sixthformers around computer" />
              </CenteredTextContainer>
            </FlexFlipWrap>
          </Section>
          <ScrollTopButton />
        </div>
      </TableOfContentsWrapper>
    </ConstrainedPageStyle>
  );
};

export default HowSAMHEWorkedPage;
